:root{
    --main-color:#322C84;
    --secondary-color:#FFFFFF;
}
body{
    /* background-color: #F9F9F9 !important; */
}
.top-bar-links a{
    text-decoration: none;
    color: #6f6f6f;
    font-weight: 600;
}
.top-bar-links .active{
    color: black;
}
.contact{
    background-color: var(--main-color) !important;
    color: var(--secondary-color) !important;
    border: none;
    padding: 10px 40px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
}
.hero b{
    color: var(--main-color);
    font-family: "Work Sans", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.9px;
}
.hero h1{
    color: #000000;
    font-family: "Volkhov", Sans-serif;
    font-size: 110px;
    font-weight: 900;
    text-transform: capitalize;
    letter-spacing: -4.1px;
}
.hero p{
    font-size: 18px ;
}
.home-card .card-title{
    font-weight: bold;
    font-size: 22px;
}


.side h2{
    color: #1D2F52;
    font-weight: 700;
    font-size: 34px;
}
.side p{
    text-align: justify;
}
.customer h1{
    color: #1D2F52;
    font-weight: 700;
}
.office{
    background-image: url(assets/office.jpg);
    background-size: cover;
    color: white;

}
.office{
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.office h1{
    font-weight: 700;
    font-size: 30px;
    line-height: 1.4;
}
.contact-form h2{
    font-size: 60px;
    font-weight: 700;
}
.icon{
    background-color: var(--main-color);
    border-radius: 10px;
}
.heading h1{
    font-size: 60px ;
    font-weight: 800;
    color: white;
}
.service-heading h1{
    font-size: 60px ;
    font-weight: 700;
    color: #48B8EB;
}
.expertise h2{
    color: #1D2F52;
    font-weight: 700;
}
.message{
    background-color: #46B2E7;
    border: none;
}
.message:hover{
    background-color: #46B2E7;
    border: none;
}
@media only screen and (max-width: 768px) {
    .hero h1{
        color: #000000;
        font-family: "Volkhov", Sans-serif;
        font-size: 42px;
        font-weight: 900;
        text-transform: capitalize;
        letter-spacing: 1.1px;
    }
}